<template>
  <div class="tiles">
    <div class="tiles1">四川南十字数据</div>
    <div class="about">
      <a href="/">主页</a> &nbsp; &nbsp; &nbsp; &nbsp;
      <a href="/blog">blog</a> &nbsp; &nbsp; &nbsp; &nbsp;
      <a href="http://www.sccrux.com" target="_blank">关于</a>
    </div>
  </div>
  <div class="mx-5">
    <v-container grid-list-xl>
      <v-row>
        <v-col cols="12" md="3" class="link">
          <v-card class="mx-auto mt-2 link_cover">
            <div class="py-4 links">
              <h3 class="pl-3 pb-3">&nbsp;目录</h3>
              <ul>
                <li
                  v-for="(nav, index) in navList"
                  :key="index"
                  :class="{ on: activeIndex === index }"
                  @click="currentClick(index)"
                >
                  <a :href="nav.url">{{ nav.title }}</a>
                  <div
                    v-if="nav.children.length > 0"
                    class="menu-children-list"
                  >
                    <ul class="nav-list">
                      <li
                        v-for="(item, idx) in nav.children"
                        :key="idx"
                        :class="{ on: childrenActiveIndex === idx }"
                        @click.stop="childrenCurrentClick(idx)"
                      >
                        <a :href="nav.url">{{ nav.title }}</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <div class="body">
            <div
              class="content markdown-body"
              ref="helpDocs"
              v-html="articalContent"
            ></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
//import marked from "marked";
//import "../../node_modules/github-markdown-css/github-markdown.css";

//import "@node_modules/github-markdown-css/github-markdown.css";
const marked = require("marked");
import "github-markdown-css/github-markdown.css";
let rendererMD = new marked.Renderer();

//import marked from "marked";
marked.setOptions({
  renderer: rendererMD,
  gfm: true, //默认为true。 允许 Git Hub标准的markdown.
  tables: true, //默认为true。 允许支持表格语法。该选项要求 gfm 为true。
  breaks: false, //默认为false。 允许回车换行。该选项要求 gfm 为true。
  pedantic: false, //默认为false。 尽可能地兼容 markdown.pl的晦涩部分。不纠正原始模型任何的不良行为和错误。
  sanitize: false, //对输出进行过滤（清理）
  smartLists: true,
  smartypants: false, //使用更为时髦的标点，比如在引用语法中加入破折号。
});

//console.log(marked);
export default {
  data() {
    return {
      articalContent: "",
      article: [],
      html: "", //文章内容
      navList: [],
      activeIndex: 0,
      docsFirstLevels: [],
      docsSecondLevels: [],
      childrenActiveIndex: 0,
    };
  },

  created() {
    var path = this.$route.query.path + ".md";
    console.log(path);
    this.$http.get("articles/" + path).then((res) => {
      //console.log(res.data);
      var htmlMD = marked.marked(res.data);
      this.articalContent = htmlMD;
      this.navList = this.handleNavTree(res.data);
      console.log(this.navList);
      this.getDocsFirstLevels(0);
    });
  },

  methods: {
    childrenCurrentClick(index) {
      this.childrenActiveIndex = index;
    },
    getDocsFirstLevels(times) {
      // 解决图片加载会影响高度问题
      setTimeout(() => {
        let firstLevels = [];
        Array.from(document.querySelectorAll("h3"), (element) => {
          firstLevels.push(element.offsetTop - 60);
        });
        this.docsFirstLevels = firstLevels;

        if (times < 8) {
          this.getDocsFirstLevels(times + 1);
        }
      }, 500);
    },
    getDocsSecondLevels(parentActiveIndex) {
      let idx = parentActiveIndex;
      let secondLevels = [];
      let navChildren = this.navList[idx].children;

      if (navChildren.length > 0) {
        secondLevels = navChildren.map((item) => {
          return this.$el.querySelector(`#data-${item.index}`).offsetTop - 60;
        });
        this.docsSecondLevels = secondLevels;
      }
    },
    getLevelActiveIndex(scrollTop, docsLevels) {
      let currentIdx = null;
      let nowActive = docsLevels.some((currentValue, index) => {
        if (currentValue >= scrollTop) {
          currentIdx = index;
          return true;
        }
      });

      currentIdx = currentIdx - 1;

      if (nowActive && currentIdx === -1) {
        currentIdx = 0;
      } else if (!nowActive && currentIdx === -1) {
        currentIdx = docsLevels.length - 1;
      }
      return currentIdx;
    },
    pageJump(id) {
      this.titleClickScroll = true;
      //这里我与原作者的不太一样，发现原作者的scrollTop一直为0，所以使用了Vuetify自带的goTo事件
      this.$vuetify.goTo(this.$el.querySelector(`#data-${id}`).offsetTop - 40);
      setTimeout(() => (this.titleClickScroll = false), 100);
    },
    currentClick(index) {
      this.activeIndex = index;
      this.getDocsSecondLevels(index);
    },
    getTitle(content) {
      let nav = [];
      let tempArr = [];
      content.replace(/(#+)[^#][^\n]*?(?:\n)/g, function (match, m1) {
        let title = match.replace("\n", "");
        let level = m1.length;
        tempArr.push({
          title: title.replace(/^#+/, "").replace(/\([^)]*?\)/, ""),
          url:
            "#" +
            title
              .replace(/^#+/, "")
              .replace(/\([^)]*?\)/, "")
              .trim(),
          level: level,
          children: [],
        });
      });

      nav = tempArr.filter((item) => item.level >= 2 && item.level <= 4);
      console.log(nav);
      let index = 0;
      return (nav = nav.map((item) => {
        item.index = index++;
        return item;
      }));
    },
    handleNavTree(data) {
      const navs = this.getTitle(data);
      //console.log(data);
      navs.forEach((item) => {
        const parentIndex = this.getParentIndex(navs, item.index);
        item.parent = parentIndex;
      });
      return this.filterArray(navs);
    },
    filterArray(data, parent) {
      const self = this;
      var tree = [];
      var temp;
      for (var i = 0; i < data.length; i++) {
        if (data[i].parent === parent) {
          var obj = data[i];
          temp = self.filterArray(data, data[i].index);
          if (temp.length > 0) {
            obj.children = temp;
          }
          tree.push(obj);
        }
      }
      return tree;
    },
    find(arr, condition) {
      return arr.filter((item) => {
        for (let key in condition) {
          if (
            condition.hasOwnProperty.call(key) &&
            condition[key] !== item[key]
          ) {
            return false;
          }
        }
        return true;
      });
    },
    getParentIndex(nav, endIndex) {
      for (var i = endIndex - 1; i >= 0; i--) {
        if (nav[endIndex].level > nav[i].level) {
          return nav[i].index;
        }
      }
    },
    appendToParentNav(nav, parentIndex, newNav) {
      let index = this.findIndex(nav, {
        index: parentIndex,
      });
      nav[index].children = nav[index].children.concat(newNav);
    },
    findIndex(arr, condition) {
      let ret = -1;
      arr.forEach((item, index) => {
        for (var key in condition) {
          if (
            condition.hasOwnProperty.call(key) &&
            condition[key] !== item[key]
          ) {
            return false;
          }
        }
        ret = index;
      });
      return ret;
    },
  },
};
</script>
<style lang="less" scoped>
.markdown-body {
  box-sizing: border-box;
  // min-width: 200px;
  //max-width: 980px;
  width: 60%;
  // margin: 0 auto;
  margin-left: 20%;
  padding: 45px;
  text-align: left;
}

@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
}
.content {
  padding: 8px 8px;
  font-size: 14px;
}
.tiles {
  height: 100px;
  display: flex;
  background: aliceblue;
}
.tiles1 {
  font-size: 40px;
  height: 100px;
  font-weight: bold;
  line-height: 100px;
  text-align: left;
  margin-left: 10px;
  background: aliceblue;
  width: 40%;
  display: flex;
}
.about {
  width: 30%;
  margin-left: 30%;
  left: 0px;
  background: aliceblue;
  font-size: 14px;
  font-weight: bold;
  line-height: 100px;
}
.body {
  margin-top: 24px;
  background: #f0ecec;
  border-radius: 5px;
}
ul {
  list-style-type: none;
  padding: 2px 6px;
}
li {
  list-style-type: none;
  margin: 2px 6px;
}
a {
  color: #414443;
  text-decoration: none;
}
@media screen and (min-width: 960px) {
  .link {
    padding-top: 50px;
    position: fixed;
    right: 25px;
    top: 50;
    width: 20%;
  }
  .link_cover {
    max-height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: visible;
    width: 20%;
  }
}
@media screen and (min-width: 1060px) {
  .link {
    padding-top: 20px;
    position: fixed;
    left: 1%;
    top: 130px;
    //   width: 200px;
    width: 19%;
    background-color: rgb(190, 185, 184);
    text-align: left;
  }
  .link_cover {
    //max-height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: visible;
    text-align: left;
    width: 20%;
  }
}
</style>